









































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment'
import { CalendarController } from '@/services/request.service'
import cloneDeep from 'lodash/cloneDeep'

@Component({
  components: {
    FullCalendar,
  },
})
export default class Calendar extends Vue {
  private loading = false
  private calendarPlugins = [dayGridPlugin]
  private calendarTime = moment()
  private scheduleVisible = false
  private eventList: Array<any> = []
  private currentCalendar: any = {}
  private firstSemStartDate = moment()
  private firstSemEndDate = moment()
  private secondSemStartDate = moment()
  private secondSemEndDate = moment()
  private schedule: any = {
    eventId: 0,
    date: undefined,
    description: '',
    startTime: moment().startOf('day'),
    endTime: moment().endOf('day'),
    allDay: true,
    location: '',
    remark: '',
  }
  private startDay = moment()
  private lastDay = moment().add(100, 'days')

  private moment = moment

  private get roleType(): string {
    return this.$store.state.roleType
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  @Watch('locale')
  private onLocaleChange(): void {
    this.getCalendar()
  }

  private mounted(): void {
    this.getCalendar()
  }

  private disabledScheduleDate(current) {
    return (
      current &&
      (current < moment(this.firstSemStartDate).startOf('day') ||
        current > moment(this.lastDay).endOf('day'))
    )
  }

  private columnHeaderHtml(date): any {
    return (
      '<span style="line-height: 52px;">' +
      this.$t('weekday[' + moment(date).day() + ']') +
      '</span>'
    )
  }

  private prevMonth(): void {
    const calendar = this.$refs.fullCalendar as any
    let calendarApi = calendar.getApi()
    calendarApi.prev()
    const date = calendarApi.getDate()
    this.calendarTime = moment(date)
    this.getCalendar()
  }

  private nextMonth(): void {
    const calendar = this.$refs.fullCalendar as any
    let calendarApi = calendar.getApi()
    calendarApi.next()
    const date = calendarApi.getDate()
    this.calendarTime = moment(date)
    this.getCalendar()
  }

  private changeMonth(value): void {
    const calendar = this.$refs.fullCalendar as any
    let calendarApi = calendar.getApi()
    calendarApi.gotoDate(value.valueOf())
    this.getCalendar()
  }

  private dayRender(dayRenderInfo): void {
    const currentCalendar = this.currentCalendar
    const date = moment(dayRenderInfo.date)
      .startOf('day')
      .valueOf()
    if (currentCalendar[date]) {
      dayRenderInfo.el.insertAdjacentHTML(
        'beforeend',
        '<span class="fc-content" aria-hidden="true" style="color: ' +
          currentCalendar[date].color +
          ';" title="' +
          currentCalendar[date].title +
          '">' +
          currentCalendar[date].title +
          '</span>'
      )
    }
  }

  private getCalendar(): void {
    this.loading = true
    const date = this.calendarTime.valueOf()
    const calendar = this.$refs.fullCalendar as any
    const calendarApi = calendar.getApi()
    const view = calendarApi.view
    const activeStart = moment(view.activeStart)
    const activeEnd = moment(view.activeEnd)
    CalendarController.getCalendarByMonth(activeStart.valueOf(), activeEnd.valueOf(), date)
      .then(res => {
        this.eventList = res.data.eventList.map(event => {
          return {
            id: event.eventId,
            title: event.description,
            start: moment(event.startDate).format('YYYY-MM-DD'),
            end: moment(event.endDate)
              .add(1, 'd')
              .format('YYYY-MM-DD'),
            allDay: event.allDay,
          }
        })
        this.firstSemStartDate = moment(res.data.firstSemStartDate)
        this.firstSemEndDate = moment(res.data.firstSemEndDate)
        this.secondSemStartDate = moment(res.data.secondSemStartDate)
        this.secondSemEndDate = moment(res.data.secondSemEndDate)
        this.startDay = moment(res.data.startDate)
        this.lastDay = moment(res.data.lastDate)
        let currentCalendar = {}
        res.data.calendarDays.forEach(item => {
          if (item.type === '1708') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.workingDay'),
              color: '#fdc33a',
            }
          } else if (item.type === '1709') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: item.description || this.$t('calendar.holiday'),
              color: '#ed5075',
            }
          } else if (item.type === '1712') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.semesterStartDate'),
              color: '#26b889',
            }
          } else if (item.type === '1713') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.semesterEndDate'),
              color: '#26b889',
            }
          } else if (item.type === '1714') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.holiday'),
              color: '#cccccc',
            }
          } else if (item.type === '1715') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.holiday'),
              color: '#cccccc',
            }
          } else if (item.type === '1716') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.holiday'),
              color: '#cccccc',
            }
          }
        })
        this.currentCalendar = cloneDeep(currentCalendar)
        calendarApi.render()
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
        const currentCalendar = this.currentCalendar
        for (let i in currentCalendar) {
          if (currentCalendar[i]) {
            const color = this.currentCalendar[i].color
            const timestamp = parseInt(i, 10)
            const date = moment(timestamp).format('YYYY-MM-DD')
            if (color === '#cccccc') {
              let dom = document.querySelector(
                'td.fc-day-top[data-date="' + date + '"] span'
              ) as HTMLElement
              if (dom) {
                dom.style.color = '#666666'
              }
            } else {
              let dom = document.querySelector(
                'td.fc-day-top[data-date="' + date + '"] span'
              ) as HTMLElement
              if (dom) {
                dom.style.color = '#fff'
                dom.style.backgroundColor = color
              }
            }
          }
        }
      })
  }

  private getBetween(start, end): Array<any> {
    let startTime = start.valueOf()
    let endTime = end.valueOf()
    let date = [] as any
    for (; startTime <= endTime; startTime += 86400000) {
      const temp = startTime
      date.push(temp.valueOf())
    }
    return date
  }

  private selectEvent(info): void {
    this.scheduleVisible = true
    const eventId = info.event.id
    CalendarController.getEventInfo(eventId)
      .then(res => {
        const schedule = {
          ...res.data,
          date: [moment(res.data.startDate), moment(res.data.endDate)],
          startTime: res.data.startTime
            ? moment(moment().format('YYYY-MM-DD') + ' ' + res.data.startTime)
            : null,
          endTime: res.data.endTime
            ? moment(moment().format('YYYY-MM-DD') + ' ' + res.data.endTime)
            : null,
        }
        this.schedule = schedule
      })
      .catch(err => {
        console.error(err)
      })
  }
}
